.notFound {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.notFound__container {

}

.notFound__heading {
  font-size: 140px;
  line-height: 169px;
  font-weight: normal;
  /* padding-top: 246px; */
  margin: 0 auto 5px;
}

.notFound__text {
  font-size: 16px;
  line-height: 19px;
  margin: 0 auto 184px;
  text-align: center;
}

.notFound__btn {
  text-decoration: none;
  font-size: 14px;
  line-height: 17px;
  margin: 0 auto 0;
  color: #4285F4;
}

/* @media (max-width: 768px) {
  .notFound__heading {
    padding-top: 408px;
  }
} */

@media (max-width: 480px) {
  .notFound__heading {
    /* padding-top: 329px; */
    font-size: 80px;
    line-height: 97px;
    margin-bottom: 10px;
  }
  .notFound__text {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 284px;
  }
  .notFound__btn {
    font-size: 12px;
    line-height: 15px;
  }
}

