
.header__container {
  display: flex;
  width: 89vw;
  margin: auto;
  min-height: 74px;
  align-items: center;
  justify-content: space-between;
  max-width: 1280px;
}

.header__nav-container {
  display: flex;
  align-items: center;
}

.header__account-btn {
  width: 100px;
  display: flex;
  flex-direction: row;
}

.header__account-text {
  font-size: 13px;
  line-height: 12px;
  margin: 0px 7px 0px 11px;
  align-self: center;
}

.header__account-logo {
  width: 26px;
  height: 26px;
  background-color: #313131;
  border-radius: 50px;
  margin-right: 3px;
  background-image: url('../../images/account-logo.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.header__nav-ul {
  padding: 0;
  margin: 0;
  display: flex;
  margin-right: 30px;
}

.header__nav-list {
  list-style-type: none;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  color: #FFF;
}

.header__nav-list_padding_on {
  padding-right: 20px;
}

.link {
  transition: opacity .4s linear;
}

.link:hover {
  opacity: .4;
  cursor: pointer;
}

.header__nav-list_bold {
  font-weight: 500;
}

.header__link {
  text-decoration: none;
  color: #FFF;

}

.header__btn {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #000;
  margin: 0;
}

.header__account-btn_enter {
  width: 76px;
  height: 32px;
  background: #3DDC84;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hamburger-menu {
  display: none;
}

.header__container_display_none {
  display: none;
}


@media (max-width: 768px) {
  .header__container {
    width: 92vw;
  }
  .header__nav-list {
    font-size: 12px;
    line-height: 16px;
  }
  .header__nav-ul {
    margin-right: 20px;
  }
  .hamburger-menu {
    position: relative;
  }

  .header__nav-container_visible_none {
    display: none;
  }

  .hamburger-menu {
    display: block;
  }

  /* скрываем чекбокс */
  #menu__toggle {
    opacity: 0;
  }
  /* стилизуем кнопку */
  .menu__btn {
    display: flex; /* используем flex для центрирования содержимого */
    align-items: center;  /* центрируем содержимое кнопки */
    position: fixed;
    top: 20px;
  /*   left: 694px; */
    right: 30px;
    width: 28px;
    height: 26px;
    cursor: pointer;
    z-index: 2;
  }
  /* добавляем "гамбургер" */
  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    display: block;
    position: absolute;
    width: 28px;
    height: 2px;
    background-color: #FFF;
  }
  .menu__btn > span::before {
    content: '';
    top: -8px;
  }
  .menu__btn > span::after {
    content: '';
    top: 8px;
  }

  /* контейнер меню */
  .menu__box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    visibility: hidden;
    padding: 0;
    top: 0;
    left: -100%;
    width: 68vw;
    height: 100vh;
    margin: 0;
    list-style: none;
    text-align: center;
    background-color: #202020;
    box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
    z-index: 1;
  }

  /* элементы меню */
  .menu__item {
    display: block;
    padding-bottom: 28px;
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    text-decoration: none;
  }
  .menu__item:first-of-type {
    padding-top: 159px;
  }

  #menu__toggle:checked ~ .menu__btn > span {
    transform: rotate(45deg);
  }
  #menu__toggle:checked ~ .menu__btn > span::before {
    top: 0;
    transform: rotate(0);
  }
  #menu__toggle:checked ~ .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
  }
  #menu__toggle:checked ~ .menu__box {
    visibility: visible;
    left: 32%;
  }

  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    transition-duration: .25s;
  }
  .menu__box {
    transition-duration: .25s;
  }
  .menu__item {
    transition-duration: .25s;
  }

  .header__account-btn_menu {
    margin: auto;
    padding-bottom: 90px;
  }

}


@media (max-width: 480px) {
  .header__nav-list {
    font-size: 10px;
  }
  .header__btn {
    font-size: 10px;
  }
  .header__container {
    width: 91vw;
  }
  .menu__btn {
    width: 22px;
  }
  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    width: 22px;
  }
  .menu__box {
    width: 100%;
  }
  #menu__toggle:checked ~ .menu__box {
    visibility: visible;
    left: 0;
  }
  .menu__item {
    padding-bottom: 24px;
  }
  .menu__item:first-of-type {
    padding-top: 144px;
  }
  .header__account-btn_menu {
    padding-bottom: 46px;
  }
}


