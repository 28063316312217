.moviesCard {
  list-style-type: none;
  position: relative;
}

.moviesCard__container {
  display: flex;
  max-width: 332px;
  margin: 10px auto 0;
  justify-content: space-between;
}

.moviesCard__name {
  width: 250px;
  height: 18px;
  margin-right: 30px;
  margin: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}

.moviesCard__duration-container {
  width: 52px;
  background: #2F2F2F;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center
}

.moviesCard__duration {
  font-size: 11px;
  line-height: 10px;
  color: #8B8B8B;
  margin: 0;
}

.moviesCard__btn {
  position: absolute;
  top: 14px;
  left: 274px;
  width: 72px;
  height: 21px;
  font-size: 10px;
  line-height: 9px;
  background: #313131;
  border-radius: 30px;
  border: none;
  color: #FFF;
  transition: opacity 0.3s linear;
  padding: 0;
}

.moviesCard__btn_saved {
  position: absolute;
  top: 14px;
  left: 325px;
  width: 21px;
  height: 21px;
  background-image: url('../../images/save333.svg');
  transition: opacity 0.3s linear;
  padding: 0;
  background-position: center;
  background-color: transparent;
  border: none;
}

.moviesCard__btn_delete {
  background-image: url('../../images/d3.svg');
}



.link-btn:hover {
  opacity: 0.8;
  cursor: pointer;
}

.moviesCard__img {
  width: 360px;
  height: 201px;
}

@media (max-width: 768px) {
  .moviesCardList {
    grid-row-gap: 47px;
    grid-template-columns: repeat(auto-fit, minmax(339px, 339px));
  }
  .moviesCard__btn {
    top: 14px;
    left: 253px;
  }
  .moviesCard__btn_saved {
    left: 304px;
  }

  .moviesCard__img {
    width: 339px;
  }
}

@media (max-width: 480px) {
  .moviesCardList {
    grid-row-gap: 39px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
  }
  .moviesCard__btn {
    top: 10px;
    left: 218px;
  }
  .moviesCard__btn_saved {
    left: 269px;
    top: 10px;
  }
  .moviesCard__img {
    width: 300px;
  }
  .moviesCard__container {
    max-width: 300px;
  }
  .moviesCard__name {
    font-size: 12px;
    line-height: 15px;
  }
}

