  .burgMenu {
    display: none;
  }

  .wrapper {
    background-color: rgba(1, 1, 1, 0.6);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    transition: background-color 1s;
  }

  .menuBurger {
    display: none;
    align-items: center;
    position: fixed;
    top: 20px;
    right: 30px;
    width: 28px;
    height: 26px;
    cursor: pointer;
    z-index: 2;
    background-image: url(/src/images/burgMenu.png);
    height: 44px;
    width: 44px;
  }

  .menuBurger_closed {
    background-image: url(/src/images/closeMenu.svg);
    background-repeat: no-repeat;
    background-position: center;
  }

  .menu__li {
    text-decoration: none;
    color: #FFF;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-top: 28px;
  }

  .menu {
    display: none;
  }

  @media screen and (max-width: 768px) {

    .burgMenu {
      display: block;
    }

    .menu {
      background: #272727;
      position: fixed;
      right: 0;
      top: 0;
      width: 68vw;
      height: 100vh;
      list-style: none;
      margin-top: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 1;
      opacity: 1;
      transition: opacity 0.5s linear;
    }

    .menu_none {
      height: 0;
      visibility: hidden;
      opacity: 0;
      transition: visibility 1s linear;
    }

    .menuBurger {
      display: inline-block;
    }
  }

