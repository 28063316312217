.promo {
  width: 94vw;
  background: #272727;
  margin: auto;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  max-width: 1280px;
}

.promo__heading {
  font-weight: normal;
  font-size: 50px;
  line-height: 58px;
  text-align: center;
  max-width: 730px;
  margin: 160px auto 151px;
  letter-spacing: -0.04em;
}

@media (max-width: 768px) {
  .promo__heading {
    font-size: 40px;
    line-height: 52px;
    margin: 350px auto 314px;
  }
}

@media (max-width: 480px) {
  .promo__heading {
    font-size: 29px;
    line-height: 37px;
    margin: 220px auto 212px;
  }
  .promo {
    width: 91vw;
  }

}
