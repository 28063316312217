.serachForm {
  max-width: 1280px;
  margin: auto;
  width: 89vw;
  border-bottom: 1px solid #424242;
  padding-bottom: 40px;
  padding-top: 80px;

}

.serachForm__form {
  width: 89vw;
  max-width: 1280px;
  margin: 0 auto 30px;
  display: flex;
  align-items: center;
}

.serachForm__input {
  background-color: #2F2F2F;
  width: 83vw;
  border: none;
  height: 48px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  color: #8B8B8B;
}

.serachForm__btn {
  min-width: 70px;
  height: 44px;
  border-radius: 50px;
  background-color: #4285F4;
  color: #FFF;
  border: none;
  margin-left: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.serachForm__filter {
  display: flex;
  align-items: center;
  width: 89vw;
  max-width: 1280px;
  margin: auto;
  justify-content: center;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(148, 141, 141);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 1px;
  bottom: 1px;
  background-color: #FFF;
  border: 1px solid #FFF;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #343434;
}

input:focus + .slider {
  box-shadow: 0 0 1px #343434;
}

input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
  background-color: #2BE080;
  border: 1px solid #FFF;

}
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.serachForm__filter-name {
  margin-left: 12px;
  font-size: 12px;
  line-height: 18px;
}

@media (max-width: 480px) {
  .serachForm__input {
    font-size: 12px;
    line-height: 18px;
  }
  .serachForm__filter-name {
    font-size: 11px;
  }

}
