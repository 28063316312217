.footer {
  width: 89vw;
  margin: auto;
  max-width: 1280px;
  padding: 79px 0 20px;
}

.footer__heading {
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #8B8B8B;
  font-weight: normal;
  margin: 0;
  margin-bottom: 20px;
}

.footer__container {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #424242;
  padding-top: 20px;
}

.footer__copyright {
  margin: 0;
  font-size: 13px;
  line-height: 16px;
}

.footer__link {
  font-size: 13px;
  line-height: 16px;
}

.footer__nav {
  list-style-type: none;
  padding: 0;
  display: flex;
  margin: 0;
}

.footer__link {
  text-decoration: none;
  color: #fff;
  margin-left: 20px;
}

@media (max-width: 480px) {
  .footer__heading {
    font-size: 12px;
    line-height: 15px;
  }
  .footer {
    width: 94vw;
    padding-bottom: 10px;
  }
  .footer__container {
    padding-top: 30px;
    flex-direction: column;
    align-items: center;
  }
  .footer__link {
    font-size: 12px;
    line-height: 15px;
    margin-left: 0;
    margin-bottom: 12px;
  }
  .footer__copyright {
    font-size: 12px;
    line-height: 15px;
    color: #8B8B8B;
    text-align: center;
    margin-top: 18px;
    order: 2;
  }
  .footer__nav {
    flex-direction: column;
    align-items: center;
  }
  .footer__list {
    margin-bottom: 12px;
  }
}


