.page {
  background-color: #202020;
  font-family: 'Inter', Arial, Helvetica, Sans-serif;
  box-sizing: border-box;
  color: #FFF;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  min-height: 100vh;
}

