.portfolio {
  width: 75vw;
  margin: 0 auto 0;
  max-width: 961px;
  padding-bottom: 23px;
}

.portfolio__heading {
  font-size: 18px;
  line-height: 20px;
  color: #8B8B8B;
  font-weight: normal;
  margin: 0 auto 50px;
}

.portfolio__container {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #424242;
  padding-bottom: 19px;
  align-items: center;
}

.portfolio__container:last-of-type {
  border-bottom: 0;
}

.portfolio__container:last-of-type {
  margin-bottom: 0;
}

.portfolio__app-type {
  font-size: 30px;
  line-height: 50px;
  letter-spacing: -0.04em;
  text-decoration: none;
  color: #fff;
}

.portfolio__img-link {
  background-image: url('../../images/sitelink.svg');
  width: 25px;
  height: 25px;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .portfolio__app-type {
    font-size: 28px;
  }
  .portfolio {
    width: 89vw;
  }
  .portfolio__container {
    border-bottom: 1px solid #E6E6E6;
  }
  .portfolio__container:last-of-type {
    padding-bottom: 11px;
  }
}

@media (max-width: 480px) {
  .portfolio__heading {
    font-size: 14px;
    margin-bottom: 40px;
  }
  .portfolio {
    width: 91vw;
    padding-bottom: 59px;
  }
  .portfolio__app-type {
    font-size: 18px;
    line-height: 28px;
  }
  .portfolio__img-link {
    width: 18px;
    height: 18px;
  }
}
