.profile {
  width: 410px;
  /* position: relative; */
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-top: 74px;
}

.profile__heading {
  margin: 0 auto 123px;
}

.profile__container {
  display: flex;
  justify-content: space-between;
}

.profile__container:first-of-type {
  border-bottom: 1px solid #424242;
  padding-bottom: 16px;
  margin-bottom: 17px;
}

.profile__text {
  font-size: 11px;
  line-height: 13px;
  color: #FFFFFF;
  margin: 0;
  background: inherit;
  border: none;
  text-align: end;
}

.profile__text_bold {
  font-weight: 500;
}

.profile__btn {
  font-size: 13px;
  line-height: 16px;
  text-decoration: none;
  color: #FFF;
  text-align: center;
  background: inherit;
  border: none;
}

.profile__btn-sbt {
  margin-top: 150px;
  margin-bottom: 16px;
}

.profile__btn_red {
  font-weight: 500;
  color: #EE3465;
}

.profile__btn_inactive {
  color: grey;
}

.profile__btn_inactive:hover {
  cursor:  not-allowed;
}

.profile__error {
  font-size: 10px;
  line-height: 12px;
  color: #EE3465;
}

.profile__error-name {
  margin: -11px 0px 10px;
}

@media (max-width: 768px) {
  .profile__heading {
    margin: 0 auto 96px;
  }
  .profile__btn_red {
    padding-bottom: 222px;
  }
}

@media (max-width: 480px) {
  .profile {
    padding-top: 70px;
    width: 81vw;
  }
  .profile__heading {
    margin: 0 auto 80px;
  }
  .profile__btn-sbt {
    margin-bottom: 23px;
  }
  .profile__btn {
    font-size: 12px;
    line-height: 15px;
  }
}

.profile__btn_red {
  padding-bottom: 30px;
}
